<template>
  <v-dialog v-model="dialog" @keydown.esc="closeDialog" max-width="800px">
    <v-card :loading="loading">
      <v-col>
        <v-card-text v-if="!companyName" class="red--text">
          Please key in company name.
        </v-card-text>
        <v-card-text v-else-if="loading">
          Checking database...
        </v-card-text>
        <v-card-text v-else-if="this.table.items.length === 0">
          Company does not exist in database.
        </v-card-text>
        <template v-else>
          <v-card-text>
            The following <b>{{ this.table.items.length }}</b> companies match the company name you
            keyed in.<br />
            Click on a company name to use its existing information.
          </v-card-text>

          <v-simple-table :dense="true" :fixed-header="true">
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="(colName, i) in table.header" :key="i">{{ colName }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in table.items" :key="i">
                  <td v-for="(colName, i) in table.header" :key="i">
                    <a
                      v-if="colName == 'Company Name' && item['Already in use'] == 'No'"
                      @click="useCompany(item.objectId)"
                    >
                      {{ item[colName] }}
                    </a>
                    <template v-else>
                      {{ item[colName] }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      companyName: null,
      dialog: false,
      table: { header: ["Company Name", "Already in use", "Country"], items: [] },
      loading: null
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    open(ccmsId, projectId, companyName) {
      this.dialog = true;
      this.companyName = companyName;
      if (companyName) this.checkCompany(ccmsId, projectId, companyName);
    },
    checkCompany(ccmsId, projectId, companyName) {
      this.loading = true;
      this.$axios
        .get("/telemarketer/calllist/tmui/check_company", {
          params: {
            companyName,
            ccmsId,
            projectId
          }
        })
        .then((res) => {
          this.loading = false;
          this.table.items = res.data.results;
        });
    },
    useCompany(objectId) {
      this.$emit("use-company", objectId);
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
thead tr th:first-child,
tbody tr td:first-child {
  width: 450px;
  min-width: 450px;
  max-width: 450px;
  word-break: break-all;
}
</style>
