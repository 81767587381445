<template>
  <v-dialog v-model="dialog" @keydown.esc="closeDialog" max-width="350px">
    <v-card>
      <v-card-title>Schedule Callback</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                name="dateSelector"
                :rules="[(v) => !!v || 'Date is required']"
                v-model="date"
                label="Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false"></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                name="timeSelector"
                :rules="[(v) => !!v || 'Time is required']"
                v-model="time"
                label="Time"
                prepend-icon="access_time"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timeMenu"
              v-model="time"
              full-width
              :allowed-minutes="(m) => m % 15 === 0"
              @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="deleteSchedule" :loading="loading.delete">
          <v-icon left>mdi-trash-can-outline</v-icon>Delete</v-btn
        >
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading.save" :disabled="!valid"
          >Schedule</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: false,
      date: null,
      dateMenu: false,
      time: null,
      timeMenu: false,
      loading: {
        save: false,
        delete: false
      },
      params: {
        objectId: undefined,
        objectType: undefined,
        ccmsId: undefined,
        phoneToDial: undefined
      }
    };
  },
  computed: {
    getTime() {
      return this.date + " " + this.time;
    }
  },
  methods: {
    closeDialog() {
      this.date = null;
      this.time = null;
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    open(ccmsId, objectId, schedule) {
      this.dialog = true;
      this.params = { ccmsId, objectId };
      if (schedule) {
        let scheduleArr = schedule.split(" ");
        this.date = scheduleArr[0];
        this.time = scheduleArr[1];
      } else {
        this.date = new Date().toISOString().substr(0, 10);
      }
    },
    save() {
      this.loading.save = true;
      this.params.schedule = this.getTime;
      this.$axios
        .post("/telemarketer/schedule/save", this.params)
        .then((res) => {
          if (res.data.errorMsg) {
            this.$store.commit("sendAlert", {
              msg: res.data.errorMsg,
              color: "error"
            });
          } else {
            this.$store.commit("sendAlert", {
              msg: "Schedule saved",
              color: "success"
            });
            this.$emit("update:success", true);
            this.closeDialog();
          }
        })
        .catch((err) => {
          this.$store.commit("sendAlert", {
            msg: err,
            color: "error"
          });
        })
        .finally(() => {
          this.loading.save = false;
        });
    },
    deleteSchedule() {
      this.loading.delete = true;
      this.params.schedule = this.getTime;
      this.$axios
        .delete("/telemarketer/schedule/delete", {
          params: {
            id: this.params.ccmsId + ";" + this.params.objectId
          }
        })
        .then(() => {
          this.$store.commit("sendAlert", {
            msg: "Schedule deleted",
            color: "success"
          });
          this.closeDialog();
          this.$emit("update:success", true);
        })
        .catch((err) => {
          this.$store.commit("sendAlert", {
            msg: err,
            color: "error"
          });
        })
        .finally(() => {
          this.loading.delete = false;
        });
    }
  }
};
</script>
