<template>
  <v-dialog v-model="dialog" @keydown.esc="closeDialog" max-width="800px">
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab
        v-show="!(params.objectType == 'COMPANY' && [1, 4, 5].includes(index))"
        v-for="(filter, index) in filters"
        :name="'history_tab_' + index"
        :key="index"
        @click="filterLog(index)"
        >{{ filter }}</v-tab
      >
    </v-tabs>

    <v-card tile>
      <v-skeleton-loader class="pa-4" :loading="loading" type="paragraph">
        <v-list three-line>
          <v-list-item v-if="activities.length == 0">
            <v-list-item-content class="text--secondary">No activities.</v-list-item-content>
          </v-list-item>
          <template v-else v-for="(activity, index) in activities">
            <v-divider v-if="index > 0" :key="activity.index"></v-divider>
            <v-list-item :key="activity.index">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  activity.ProjectName
                }}</v-list-item-title>
                <v-list-item-subtitle class="text--primary">
                  {{ activity.AttrName }}
                  <template v-if="activity.AttrValue != null">:&nbsp;</template>
                  <span class="font-weight-medium" v-text="activity.AttrValue" />
                  <br v-if="activity.AttrName" />
                  {{ activity.Activity }} by
                  <span class="font-weight-medium" v-text="activity.UserName" /> on
                  <span class="font-weight-medium" v-text="activity.AddDate" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-skeleton-loader>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      source: undefined,
      dialog: false,
      activities: [],
      filters: ["Show All", "Email", "TM", "Dial", "Registration", "Linkedin"],
      tab: null,
      loading: null,
      params: {}
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    filterLog(i) {
      this.source && this.source.cancel("Operation canceled due to new request.");
      this.source = axios.CancelToken.source();
      this.loading = true;
      this.params.filter = i;

      this.$axios
        .get("/telemarketer/calllist/tmui/history", {
          params: this.params,
          cancelToken: this.source.token
        })
        .then((res) => {
          this.activities = res.data;
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            // request cancelled
            console.log("CANCELLED");
          } else {
            // handle error
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open(ccmsId, projectId, objectType, objectId) {
      this.tab = 0;
      this.dialog = true;
      this.params = { ccmsId, projectId, objectType, objectId };
      this.filterLog(0);
    }
  }
};
</script>
