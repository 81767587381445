<template>
  <div style="display: contents">
    <v-card class="dialogForm" :loading="loading.form" height="100%" width="100%">
      <v-system-bar dark color="black" height="20px">
        <v-icon>mdi-phone</v-icon>
        <span>
          Dials:
          <b>{{ lastContacted.dial.count }}</b> | Last Dialed:
          <b>{{ lastContacted.dial.last }}</b>
        </span>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-arrow-left</v-icon>
        <span>
          Total Contacted:
          <b>{{ lastContacted.contact.count }}</b> | Last Contacted:
          <b>{{ lastContacted.contact.last }}</b>
          | Last Updated:
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <b v-on="on">{{ lastContacted.update.last }}</b>
            </template>
            <span v-html="lastContacted.update.detail"></span>
          </v-tooltip>
        </span>
      </v-system-bar>
      <v-toolbar dense color="primary" dark>
        <template v-for="item in toolbarItem">
          <span v-if="!item.hide" :key="item.action">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :color="item.action == 'Hangup' ? 'accent' : null"
                  tile
                  text
                  v-on="on"
                  @click="handleAction(item.action)"
                  :disabled="checkToolboxDisable(item)"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                  <template v-if="item.label">
                    <div
                      class="ml-1 caption"
                      style="line-height: 12px; width:70px; white-space: normal;"
                    >
                      {{ item.label }}
                    </div>
                  </template>
                </v-btn>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </span>
        </template>
        <v-spacer />
        <v-btn
          outlined
          @click="save"
          :loading="loading.save"
          :disabled="
            (loading.form || this.modifiedAttr.length == 0) && !this.formProperty.addExisting
          "
          >Save</v-btn
        >
      </v-toolbar>

      <v-container fluid>
        <v-form ref="form" v-model="form.valid" :lazy-validation="true">
          <v-row no-gutters>
            <v-col cols="8">
              <v-container ref="leftBox" class="pa-0 pr-1">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card class="mb-2" outlined tile>
                      <v-card-title class="pb-0 ma-0">
                        Company
                        <v-spacer />
                        <ActionButton
                          v-if="isProjectLevel && formProperty.companyId == 0"
                          icon="mdi-account-search"
                          tooltip="Check for duplicate companies"
                          @action-click="handleAction('checkCompany')"
                        />
                        <ActionButton
                          v-if="isProjectLevel"
                          icon="mdi-account-clock"
                          tooltip="View company history"
                          @action-click="handleAction('ViewCompanyHistory')"
                        />
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="(item, index) in attributes.company"
                            :key="index"
                            :cols="item.cols"
                            v-show="
                              item.address_type === undefined || item.address_type === addressToggle
                            "
                          >
                            <v-btn-toggle
                              color="primary"
                              v-model="addressToggle"
                              v-if="item.ATTR_ID == 'ADDRESS_TOGGLE'"
                            >
                              <v-btn x-small text>Street Address</v-btn>
                              <v-btn x-small text>Mailing Address</v-btn>
                            </v-btn-toggle>

                            <InputControl
                              v-else
                              :noPrependEmptyVal="item.address_attrs"
                              :label="item.label"
                              :ref="item.ATTR_ID"
                              :attrId="item.ATTR_ID"
                              :value.sync="item.value"
                              :attrType="item.ATTR_TYPE"
                              :attrRef="item.REF_VALUE"
                              :activeIndex="item.activeIndex"
                              :rules="item.validation"
                              :multiValue="item.MULTI_VALUE"
                              :multiAttr="item.MULTI_ATTR"
                              @update:value="handleInputChange(item)"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="" outlined tile>
                      <v-card-title class="pb-0">
                        Contact
                        <v-spacer />
                        <ActionButton
                          v-if="isProjectLevel"
                          :disabled="!contactList.active > 0"
                          icon="mdi-account-clock"
                          tooltip="View company history"
                          @action-click="handleAction('ViewObjectHistory')"
                        />
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="9">
                            <v-select
                              hide-details
                              outlined
                              dense
                              :loading="loading.contactDD"
                              v-model="contactList.active"
                              :items="validList"
                              label="Contact List"
                              @change="changeContact"
                            ></v-select>
                          </v-col>
                          <v-col cols="3" align-self="center">
                            <v-checkbox
                              v-if="isProjectLevel"
                              hide-details
                              dense
                              v-model="showBadContact"
                              label="Show Bad Contacts"
                              name="showBadContactCB"
                            ></v-checkbox>
                          </v-col>

                          <template v-for="(item, index) in attributes.contact">
                            <template v-if="item.ATTR_ID == '3000855745'">
                              <v-col cols="11" :key="`${index}-1`">
                                <v-text-field
                                  :name="'ctrl_' + item.ATTR_ID"
                                  placeholder=" "
                                  clearable
                                  hide-details
                                  dense
                                  prefix="https://www.linkedin.com/in/"
                                  :label="item.label"
                                  v-model="item.value"
                                  @input="handleInputChange(item)"
                                  :disabled="contactList.active === ''"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1" :key="`${index}-2`">
                                <v-btn
                                  icon
                                  @click="openLinkedIn(item.value)"
                                  :disabled="!item.value"
                                >
                                  <v-icon>mdi-open-in-new</v-icon>
                                </v-btn>
                              </v-col>
                            </template>

                            <v-col v-else :key="index" :cols="item.cols">
                              <InputControl
                                :disabled="contactList.active === ''"
                                :noPrependEmptyVal="item.address_attrs"
                                :label="item.label"
                                :ref="item.ATTR_ID"
                                :attrId="item.ATTR_ID"
                                :value.sync="item.value"
                                :attrType="item.ATTR_TYPE"
                                :attrRef="item.REF_VALUE"
                                :rules="item.validation"
                                :multiValue="item.MULTI_VALUE"
                                :multiAttr="item.MULTI_ATTR"
                                @update:value="handleInputChange(item)"
                              />
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="4">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card
                      class="mb-2"
                      outlined
                      tile
                      :height="rightBoxHeight"
                      style="overflow-y: scroll"
                    >
                      <v-card-title class="pb-0">Campaign</v-card-title>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="(item, index) in attributes.campaign"
                            :key="index"
                            cols="12"
                          >
                            <InputControl
                              :label="item.label"
                              :ref="item.ATTR_ID"
                              :attrId="item.ATTR_ID"
                              :value.sync="item.value"
                              :attrType="item.ATTR_TYPE"
                              :attrRef="item.REF_VALUE"
                              :multiValue="item.MULTI_VALUE"
                              :control="item.INPUT_TYPE"
                              :disabled="
                                item.DISABLED ||
                                  (item.OBJECT_TYPE == 'CONTACT' && contactList.active === '')
                              "
                              :rules="item.validation"
                              @update:value="handleInputChange(item)"
                            />
                            <v-text-field
                              v-if="item.OTHER && item.value == 'OTHER'"
                              v-model="otherValues[item.ATTR_ID]"
                              hide-details="auto"
                              dense
                              outlined
                              class="mt-2"
                              :label="item.OTHER"
                              :rules="[(v) => !!v || 'Required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <Dial ref="dialDialog" @dialClick="handleAction('Dialing')" @hangup="fetchHeader()" />
    <ScheduleCallback ref="scheduleDialog" @update:success="fetchHeader()" />
    <SendMail ref="mailDialog" @update:success="mailSentEvent($event)" />
    <ObjectHistory ref="objectHistory" />
    <CheckCompany ref="checkCompany" @use-company="useCompany" />
    <CheckDuplicate
      v-if="isProjectLevel"
      ref="checkDuplicate"
      @contact-clicked="
        $router.push({
          query: {
            ...$route.query,
            ...{ contactId: $event.CONTACT_OBJECT_ID, companyId: $event.COMPANY_OBJECT_ID }
          }
        })
      "
    />
  </div>
</template>

<script>
import axios from "axios";
import Dial from "@/components/telemarketer/tmui/Dial";
import ScheduleCallback from "@/components/telemarketer/tmui/ScheduleCallback";
import SendMail from "@/components/telemarketer/tmui/SendMail";
import ObjectHistory from "@/components/telemarketer/tmui/ObjectHistory";
import InputControl from "@/components/util/InputControl";
import CheckCompany from "@/components/telemarketer/tmui/CheckCompany";
import CheckDuplicate from "@/components/telemarketer/tmui/CheckDuplicate";
import ActionButton from "@/components/util/ActionButton";

var source;

export default {
  components: {
    ActionButton,
    Dial,
    ScheduleCallback,
    SendMail,
    ObjectHistory,
    InputControl,
    CheckCompany,
    CheckDuplicate
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.load(
          this.$route.query.companyId,
          this.$route.query.contactId,
          this.$route.query.ccmsId,
          this.$route.query.type,
          this.$route.query.lrbtn,
          this.$route.query.addExisting,
          this.$route.query.campaignId,
          this.$route.query.projectId
        );
      }
    }
  },
  computed: {
    validList() {
      return this.contactList.items.filter(
        (item) =>
          item.bad_contact == this.showBadContact ||
          !item.bad_contact ||
          item.value == this.contactList.active
      );
    },
    isProjectLevel() {
      return this.formProperty.ccmsId || this.formProperty.projectId;
    }
  },
  data() {
    return {
      listBoundary: { min: false, max: false },
      rightBoxHeight: "936px",
      formProperty: {},
      lastContacted: {
        dial: {
          last: "-",
          count: 0
        },
        mail: {
          last: "-",
          count: 0
        },
        contact: {
          last: "-",
          count: 0
        },
        update: {
          last: "-",
          detail: ""
        }
      },
      loading: {
        save: false,
        form: false,
        contactDD: false
      },
      form: {
        fieldsTovalidate: {},
        valid: true
      },
      showBadContact: false,
      addressToggle: 0,
      modifiedAttr: [],
      attributes: {
        company: {},
        contact: {},
        campaign: {}
      },
      otherValues: {},
      contactList: { items: [], active: null },
      toolbarItem: [
        {
          icon: "mdi-reload",
          tooltip: "Refresh",
          action: "Refresh"
        },
        {
          icon: "mdi-phone",
          tooltip: "Dial",
          action: "Dial"
        },
        {
          icon: "mdi-phone-hangup",
          tooltip: "Hangup",
          action: "Hangup",
          hide: true,
          label: "Hangup"
        },
        {
          icon: "mdi-calendar-edit",
          tooltip: "Schedule Callback",
          action: "Schedule"
        },
        {
          icon: "mdi-calendar-clock",
          tooltip: "",
          action: "CheckSchedule",
          label: "",
          hide: true
        },
        {
          icon: "mdi-email-edit",
          tooltip: "Send Mail",
          action: "Mail"
        },
        {
          icon: "mdi-email-send",
          tooltip: "",
          action: "MailStatus",
          label: "",
          hide: true
        },
        {
          icon: "mdi-arrow-left",
          action: "Back"
        },
        {
          icon: "mdi-arrow-right",
          action: "Next"
        }
      ]
    };
  },
  mounted() {
    window.addEventListener("beforeunload", this.close);
    window.addEventListener("message", this.handleEvent);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.close);
    window.removeEventListener("message", this.handleEvent);
  },
  methods: {
    handleEvent(e) {
      e = e.data;
      if (e.call == "load") {
        this.$router.push({ query: e.data });
      }
    },
    mailSentEvent($e) {
      if ($e.status == "success") {
        let i = this.attributes.campaign.findIndex((e) => e.ATTR_ID == $e.attr.attr_id);
        if (i > -1) {
          this.attributes.campaign[i].value = $e.attr.value_id;
        }
      }

      this.fetchHeader();
    },

    close(event) {
      if (this.modifiedAttr.length > 0) {
        event.preventDefault();
        event.returnValue = "";
      }
      return "";
    },
    handleAction(action) {
      switch (action) {
        case "Refresh":
          if (this.modifiedAttr.length > 0) {
            this.$root
              .$confirm("Unsaved Changes", "Are you sure you want to refresh this form?", {
                color: "red"
              })
              .then((confirm) => {
                if (confirm) {
                  this.fetchItem();
                }
              });
          } else {
            this.fetchItem();
          }

          break;
        case "Dial": {
          let objectId = this.formProperty.companyId;
          let objectType = "COMPANY";
          if (this.formProperty.contactId) {
            objectId = this.formProperty.contactId;
            objectType = "CONTACT";
          }
          this.$refs.dialDialog.open(
            this.formProperty.ccmsId,
            this.formProperty.campaignId,
            this.formProperty.projectId,
            objectId,
            objectType,
            this.attributes
          );
          break;
        }
        case "Dialing":
          this.toolbarItem.find((e) => e.action == "Hangup").hide = false;
          this.toolbarItem.find((e) => e.action == "Dial").hide = true;
          break;
        case "Hangup":
          this.toolbarItem.find((e) => e.action == "Hangup").hide = true;
          this.toolbarItem.find((e) => e.action == "Dial").hide = false;
          this.$refs.dialDialog.hangup();
          break;
        case "Schedule":
          this.$refs.scheduleDialog.open(
            this.formProperty.ccmsId,
            this.formProperty.contactId,
            this.toolbarItem.find((e) => e.action == "CheckSchedule").label
          );
          break;
        case "ViewObjectHistory":
          this.$refs.objectHistory.open(
            this.formProperty.ccmsId,
            this.formProperty.projectId,
            "CONTACT",
            this.formProperty.contactId
          );
          break;
        case "ViewCompanyHistory":
          this.$refs.objectHistory.open(
            this.formProperty.ccmsId,
            this.formProperty.projectId,
            "COMPANY",
            this.formProperty.companyId
          );
          break;
        case "checkCompany":
          this.$refs.checkCompany.open(
            this.formProperty.ccmsId,
            this.formProperty.projectId,
            this.attributes.company.find((e) => e.ATTR_ID == 1000001013).value
          );
          break;
        case "Mail":
          this.$refs.mailDialog.open(
            this.formProperty.ccmsId,
            this.formProperty.projectId,
            this.formProperty.contactId,
            this.attributes.contact
          );
          break;
        case "Back":
          this.tranverseContact("-");
          break;
        case "Next":
          this.tranverseContact("+");
          break;
        default:
          break;
      }
    },
    handleInputChange(item) {
      if (this.modifiedAttr.indexOf(item.ATTR_ID) == -1) {
        this.modifiedAttr.push(item.ATTR_ID);
      }
      if (item.address_attrs) {
        this.attributes = updateActiveIndex([item], this.attributes);
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.modifiedAttr.length > 0 || this.formProperty.addExisting) {
          let skipContactUpdate = this.contactList.active === "" ? true : false;
          //add address id to new contact
          if (this.formProperty.contactId === 0 && !skipContactUpdate) {
            let addAttrs = this.attributes.company.filter((e) => e.address_attrs);
            addAttrs.forEach((e) => {
              this.modifiedAttr.push(e.ATTR_ID);
            });
          }
          let data = extractValues(this.attributes, this.modifiedAttr);

          this.loading.save = true;
          this.axios
            .put("/telemarketer/calllist/tmui/save", {
              ccmsId: this.formProperty.ccmsId,
              projectId: this.formProperty.projectId,
              campaignId: this.formProperty.campaignId,
              COMPANY_OBJECT_ID: this.formProperty.companyId,
              CONTACT_OBJECT_ID: this.formProperty.contactId,
              skipContactUpdate: skipContactUpdate,
              addExisting: this.formProperty.addExisting,
              data: data,
              otherValues: this.otherValues
            })
            .then((res) => {
              let statusMsg = "Form saved";
              if (res.data.blacklistEmail) {
                statusMsg =
                  res.data.blacklistEmail +
                  " found In Blacklist Table. Contact Status flagged to Do Not Call";
              }
              this.$store.commit("sendAlert", {
                msg: statusMsg,
                color: "success"
              });

              if (
                this.formProperty.contactId != res.data.contactId ||
                this.formProperty.companyId != res.data.companyId
              ) {
                this.updateDV();
                this.$router.push({
                  query: {
                    ...this.$route.query,
                    ...{ contactId: res.data.contactId, companyId: res.data.companyId }
                  }
                });
              } else {
                this.updateDVRow(this.formProperty.companyId, this.formProperty.contactId);
                this.fetchItem();
                this.fetchContactList();
                this.formProperty.addExisting = false;
              }
            })
            .finally(() => {
              this.loading.save = false;
            });
        } else {
          this.$store.commit("sendAlert", {
            msg: "No changes have been made"
          });
        }
      } else {
        for (let attr of Object.values(this.form.fieldsTovalidate)) {
          if (this.$refs[attr][0].focusIfInvalid()) {
            break;
          }
        }

        this.$store.commit("sendAlert", {
          msg: "Please verify the fields in red",
          color: "error"
        });
      }
    },
    checkToolboxDisable(item) {
      let disabled = false;
      if (
        !this.loading.form &&
        ((this.formProperty.objectType == "COMPANY" && this.formProperty.companyId) ||
          this.formProperty.objectType == "CONTACT")
      ) {
        switch (item.action) {
          case "Back":
            disabled = this.listBoundary.min;
            break;
          case "Next":
            disabled = this.listBoundary.max;
            break;
          case "Mail":
            disabled = !(this.formProperty.contactId > 0);
            break;
          case "Schedule":
            disabled = !(this.formProperty.contactId > 0);
            break;
        }
      } else {
        disabled = true;
      }
      return disabled;
    },
    updateDV() {
      if (window.opener) {
        window.opener.postMessage({ call: "reload" }, "*");
      }
    },
    updateDVRow(companyId, contactId) {
      if (window.opener) {
        window.opener.postMessage(
          { call: "update", companyId: companyId, contactId: contactId },
          "*"
        );
      }
    },
    tranverseContact(direction) {
      if (window.opener) {
        window.opener.postMessage(
          { call: "tranverse", direction: direction, objectType: this.formProperty.objectType },
          "*"
        );
      }
    },
    changeContact(contactId) {
      this.$router.push({ query: { ...this.$route.query, ...{ contactId: contactId } } });
    },
    useCompany(companyId) {
      this.$router.push({ query: { companyId: companyId, contactId: 0, addExisting: true } });
    },
    load(
      companyId,
      contactId,
      ccmsId,
      type,
      lrbtn = null,
      addExisting = false,
      campaignId = null,
      projectId = null
    ) {
      let contactOnly = false;
      //no need refetch company if no change
      if (this.formProperty.companyId == companyId) {
        contactOnly = true;
      }
      this.formProperty = { companyId, contactId, ccmsId, addExisting, campaignId, projectId };
      this.formProperty.objectType = type == "ct" ? "CONTACT" : "COMPANY";
      let objectTypeLabel = this.formProperty.objectType == "COMPANY" ? "Company" : "Contact";
      if (!window.opener || !lrbtn || !type) {
        this.toolbarItem.find((e) => e.action == "Back").hide = true;
        this.toolbarItem.find((e) => e.action == "Next").hide = true;
      } else {
        this.listBoundary.min = lrbtn.charAt(0) == 0;
        this.listBoundary.max = lrbtn.charAt(1) == 0;
      }
      this.toolbarItem.find((e) => e.action == "Back").tooltip = "Previous " + objectTypeLabel;
      this.toolbarItem.find((e) => e.action == "Next").tooltip = "Next " + objectTypeLabel;
      this.toolbarItem.find((e) => e.action == "Mail").hide = !this.isProjectLevel;
      this.toolbarItem.find((e) => e.action == "Schedule").hide = !this.isProjectLevel;

      if (companyId) {
        this.fetchItem(contactOnly);
        this.fetchContactList();
      }
    },
    fetchContactList() {
      let defaultListOptions = [
        { text: "", value: "", bad_contact: false },
        { text: "<< Create New Contact >>", value: 0, bad_contact: false }
      ];
      if (this.formProperty.companyId) {
        this.loading.contactDD = true;
        this.$axios
          .get("/telemarketer/calllist/tmui/contact_list", {
            params: {
              COMPANY_OBJECT_ID: this.formProperty.companyId,
              ccmsId: this.formProperty.ccmsId,
              campaignId: this.formProperty.campaignId,
              projectId: this.formProperty.projectId
            }
          })
          .then((res) => {
            this.contactList.items = defaultListOptions.concat(res.data);
            this.contactList.active = this.formProperty.contactId
              ? parseInt(this.formProperty.contactId)
              : "";
          })
          .finally(() => {
            this.loading.contactDD = false;
          });
      } else {
        this.contactList.items = defaultListOptions;
        this.contactList.active = "";
      }
    },
    updateToolbarItem(res) {
      this.toolbarItem.forEach((e) => {
        switch (e.action) {
          case "MailStatus":
            if (res?.data.mail.count > 0) {
              e.label = res.data.mail.last;
              e.tooltip = res.data.mail.count + " Email Sent";
              e.hide = false;
            } else {
              e.hide = true;
            }
            break;
          case "CheckSchedule":
            if (res?.data.schedule) {
              e.label = res.data.schedule;
              e.tooltip = "Callback Scheduled on " + res.data.schedule;
              e.hide = false;
            } else {
              e.hide = true;
              e.tooltip = "";
              e.label = "";
            }
            break;
        }
      });
    },
    fetchHeader() {
      if (!this.formProperty.companyId) {
        Object.assign(this.lastContacted, this.$options.data.call(this).lastContacted);
        this.updateToolbarItem();
      } else {
        this.$axios
          .get("/telemarketer/calllist/tmui/header", {
            params: this.formProperty,
            cancelToken: source.token
          })
          .then((res) => {
            this.lastContacted = res.data;
            this.updateToolbarItem(res);
          })
          .catch(() => {});
      }
    },
    fetchItem(contactOnly) {
      source && source.cancel("Operation canceled due to new request.");
      source = axios.CancelToken.source();
      let OBJECT_ID =
        this.formProperty.contactId > 0 ? this.formProperty.contactId : this.formProperty.companyId;
      let ccmsId = this.formProperty.ccmsId;
      let campaignId = this.formProperty.campaignId;
      let projectId = this.formProperty.projectId;
      this.modifiedAttr = [];
      this.otherValues = {};
      this.loading.form = true;
      this.fetchHeader();
      this.$nextTick(() => {
        if (this.formProperty.contactId && this.isProjectLevel) {
          this.$refs.checkDuplicate.checkDuplicate(ccmsId, projectId, this.formProperty.contactId);
        }
      });

      this.axios
        .get("/telemarketer/calllist/tmui/attributes", {
          params: { OBJECT_ID, ccmsId, projectId, contactOnly, campaignId },
          cancelToken: source.token
        })
        .then((res) => {
          if (!contactOnly) {
            this.attributes.company = res.data.company;
          }
          this.attributes.contact = res.data.contact;
          this.attributes.campaign = res.data.campaign;
          this.form.fieldsTovalidate = res.data.fieldsTovalidate;
          this.$refs.form.resetValidation();
        })
        .catch(() => {})
        .finally(() => {
          this.loading.form = false;
          this.alignContainerHeight();
        });
    },
    alignContainerHeight() {
      this.rightBoxHeight = this.$refs.leftBox.clientHeight;
    },
    openLinkedIn(userName) {
      window.open("https://www.linkedin.com/in/" + userName, "_blank");
    }
  }
};

function extractValues(attributes, modifiedAttr) {
  let data = [];
  for (let [type, attrs] of Object.entries(attributes)) {
    for (let attr of Object.values(attrs)) {
      // use contact for address_id and only update modified attr
      if ((type == "company" && attr.address_attrs) || !modifiedAttr.includes(attr.ATTR_ID)) {
        continue;
      }

      data.push({ ATTR_ID: attr.ATTR_ID, value: attr.value });
    }
  }

  return data;
}

// to select correct value according to address id
function updateActiveIndex(addresses, attributes) {
  addresses.forEach((e) => {
    e.address_attrs.dest.forEach((attrId) => {
      attributes.company.map((attr) => {
        if (attr.ATTR_ID == attrId) {
          // for dest
          attr.activeIndex = e.value;
        } else if (attr.ATTR_ID == e.ATTR_ID) {
          // for src
          attr.value = e.value;
        }

        return attr;
      });
    });
  });

  return attributes;
}
</script>
