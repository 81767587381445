<template>
  <v-dialog v-model="dialog" @keydown.esc="closeDialog" max-width="800px">
    <v-card>
      <v-col>
        <v-card-title>
          Duplicate contacts found:
        </v-card-title>
        <v-simple-table :dense="true">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(colName, i) in table.header" :key="i">{{ colName }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in table.items" :key="i">
                <td>{{ item.Attribute }}</td>
                <td class="text--disabled">{{ item.Current }}</td>
                <td
                  v-for="(dup, i) in item.Duplicates"
                  :key="i"
                  :class="dup.matched ? 'matched' : ''"
                >
                  <template v-if="dup.CONTACT_OBJECT_ID">
                    <a @click="contactClicked(dup)">{{ dup.value }}</a>
                  </template>
                  <template v-else>
                    {{ dup.value }}
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-card-actions>
        <v-btn color="primary" block @click="closeDialog" name="dismissBtn">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
var source;
export default {
  data() {
    return {
      dialog: false,
      table: { header: [], items: [] }
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    checkDuplicate(ccmsId, projectId, objectId) {
      source && source.cancel("Operation canceled due to new request.");
      source = axios.CancelToken.source();
      this.$axios
        .get("/telemarketer/calllist/tmui/check_duplicate", {
          params: {
            objectId,
            ccmsId,
            projectId
          },
          cancelToken: source.token
        })
        .then((res) => {
          if (res.data.dupcount > 0) {
            this.table.items = res.data.items;
            this.table.header = res.data.header;
            this.dialog = true;
          }
        });
    },
    cancelCheckDuplicate() {
      source && source.cancel("Check duplicate cancelled");
      this.dialog = false;
    },
    contactClicked(item) {
      this.$emit("contact-clicked", item);
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
thead tr th:first-child,
tbody tr td:first-child {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
  word-break: break-all;

  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: #fff;
  border-right: 1px solid #ccc;

  left: 0;
  z-index: 1 !important;
}

.matched {
  color: #800000;
  font-weight: bold;
}
</style>
